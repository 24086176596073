.App {
  text-align: center;
  margin:0;

}

#root {
  overflow: hidden; 
}
body,html {
  height: 100vh;
  margin: 0;
  overflow: hidden; /* Hide scrollbars */
}

body {
  position: relative;
}
body {
  -webkit-overflow-scrolling: touch;
}

.box {
  /* height:100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.image-box {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
  width: 100%
}

.image {
  height:500px ;
  width: auto 
}


.email-box {
  position: fixed;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 10px;

  font-family: "Arial";
  font-size: 18px;
  font-stretch: ultra-expanded;
  left: 50%;
  transform: translateX(-50%);
  
}

.contact-div{
  cursor: pointer;
}

.email {
  text-decoration: none;
  color: inherit; 
}



@font-face {
  font-family: "Gambetta";   /*Can be any text*/
  src: local("GambettaMedium"),
    url("./fonts/Gambetta-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gambetta-Semibold";   /*Can be any text*/
  src: local("GambettaMedium"),
    url("./fonts/Gambetta-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Condensed";   /*Can be any text*/
  src: local("helvetica_condensed"),
    url("./fonts/helvetica_condensed.ttf") format("truetype");
}

@font-face {
  font-family: "Arial";   /*Can be any text*/
  src: local("arial"),
    url("./fonts/arial.ttf") format("truetype");
}

.card-div {
  z-index: 3 !important;
}


.buro-img {
  height: 45px;
  width: auto
}

.email-img {
  height: 18px;
  width: auto
}
.cover-img {
  transform: rotate(355deg);
  width: auto;
  height: 200px;
}

.cover-div {
  transform: rotate(355deg);
  height: 200px;
  width: 350px;
  background-color: #c40d00;
}

.title {
  margin-top:40px;
  font-family: "Arial";
  font-stretch: ultra-expanded;
}

@media only screen and (max-device-width: 480px) {
  
  .box {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-box {
    height:400px ;
    width: auto
  }


  .cover-img {
    height: 100px;
  }
  
  .cover-div {
    height: 100px;
    width: 175px;
  }

  .image {
    height:400px ;
    width: auto
  }


}